<template>
    <div class="messages">
        <div class="message_Title">
            <el-button type="primary" icon="el-icon-search">搜索</el-button>
            <div class="messBth">
                <input v-model="unread" type="checkbox" @change="handleUnread"> <span>只看未读</span>
            </div>

        </div>

        <div class="tabs">
            <el-table :data="memberMessageList" style="width: 100%" :cell-style="handleCell" border
                :header-row-style="{ height: '50px' }" :row-style="{ height: '56px' }" :cell-class-name="returnName"
                :header-cell-style="{ background: 'rgba(153, 153, 153, .1)' }" show-overflow-tooltip
                @row-click="handleTableRow">
                <el-table-column prop="createTime" label="通知时间" width="180">
                </el-table-column>
                <el-table-column class="abc" label="状态" width="180" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>
                            <span class="weidu" v-if="scope.row.isRead == 0">未读</span>
                            <span class="yidu" v-if="scope.row.isRead == 1">已读</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="通知内容" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-html="scope.row.content" class="tabsContent">
                            {{scope.row.content}}
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="paging">
                <el-pagination background @current-change="handleSizeChange" layout="prev, pager, next"
                    :total="paging.total" :page-size="paging.size">
                </el-pagination>
            </div>
        </div>

        <div class="mask" v-show="flagRead">
            <div class="isreadBox">
                <!-- 通知时间 -->
                <div class="datas">
                    <span>通知时间</span>
                    <p>{{ notificationTime }}</p>
                </div>
                <!-- 通知内容 -->
                <div class="contentRead">
                    <span v-html="noticeContent">{{ noticeContent }}</span>
                </div>
                <!-- 我已阅读 -->
                <div class="readY" @click="handleReadY">我已阅读</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "MessageManagement_Component",
    data() {
        return {
            unread: false,
            flagRead: false,

            memberMessageList: [],
            notificationTime: "", //通知时间
            noticeContent: "", //通知内容
            readId: 0,
            formData: {
                pageNo: 1, //当前页
            },
            paging: {
                total: 0,
                size: 5,
            },
        }
    },
    created() {
        this.getmemberMessage()


    },
    mounted() {

    },
    methods: {
        getmemberMessage() {
            var token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/memberMessage/list`, {
                //参数列表
                params: { pageNo: 1, },
                //请求头配置  
                headers: { 'authorization': token }
            }).then(res => {
                console.log(res.data.result)
                this.paging.total = res.data.result.total
                this.memberMessageList = res.data.result.records;

            })
        },



        handleUnread() {  //只看未读
            var token = sessionStorage.getItem("token")
            if (this.unread) {
                axios.get(`${baseUrl}/memberMessage/list`, {
                    //参数列表
                    params: { pageNo: 1, isRead: 0 },
                    //请求头配置  
                    headers: { 'authorization': token }
                }).then(res => {
                    console.log(res.data.result);
                    this.paging.total = res.data.result.total
                    this.memberMessageList = res.data.result.records;
                })
            } else {
                axios.get(`${baseUrl}/memberMessage/list`, {
                    //参数列表
                    params: { pageNo: 1, },
                    //请求头配置  
                    headers: { 'authorization': token }
                })
                    .then(res => {
                        this.memberMessageList = res.data.result.records;
                        this.paging.total = res.data.result.total;
                        let reg = new RegExp('<[^<>]+>', 'g');

                    })
            }
        },
        handleCell(data) {
            // console.log(data);
        },
        returnName(obj) {
            //columnIndex  就是第几列的意思
            //当时 第一列的时候  返回tableTextColor 当的class
            //否则 不处理不添加class
            // if (obj.columnIndex == 1 && !this.unread) {
            //     return "tableTextColor_1"
            // } else if (obj.columnIndex == 1 && this.unread) {
            //     return "tableTextColor_2"
            // }
            // console.log(obj.columnIndex);
        },

        handleTableRow(row) {  //点击某一行
            // console.log(row);
            this.readId = row.id
            this.notificationTime = row.createTime;
            this.noticeContent = row.content;
            this.flagRead = true;

        },
        handleReadY() {  //我已阅读

            var token = sessionStorage.getItem("token")
            axios.post(`${baseUrl}/memberMessage/read`, {
                id: this.readId,
                isRead: 1
            }, {
                headers: {
                    'authorization': token
                }
            })
                .then(res => {
                    // console.log(res);
                    if (res.data.success == true) {
                        // this.getmemberMessage();
                        var token = sessionStorage.getItem("token")
                        axios.get(`${baseUrl}/memberMessage/list`, {
                            //参数列表
                            params: { pageNo: this.formData.pageNo, },
                            //请求头配置  
                            headers: { 'authorization': token }
                        })
                            .then(res => {
                                console.log(res.data.result)
                                this.paging.total = res.data.result.total
                                this.memberMessageList = res.data.result.records;
                                // let reg = new RegExp('<[^<>]+>', 'g');
                                // var textList = this.memberMessageList;
                                // // textList.forEach((v, i) => {
                                // //     this.memberMessageList[i].content = v.content.replace(reg, "");
                                // //     // console.log(this.memberMessageList);
                                // // })
                            })
                            .catch(err => {
                                console.error(err);
                            });
                        this.flagRead = false;
                    }
                })
                .catch(err => {
                    console.error(err);
                })

        },

        handleSizeChange(val) { //分页
            this.formData.pageNo = val
            var token = sessionStorage.getItem("token");
            if (this.unread) {
                axios.get(`${baseUrl}/memberMessage/list`, {
                    //参数列表
                    params: { pageNo: 1, isRead: 0 },
                    //请求头配置  
                    headers: { 'authorization': token }
                }).then(res => {
                    console.log(res.data.result);
                    this.paging.total = res.data.result.total
                    this.memberMessageList = res.data.result.records;
                })
            } else {
                axios.get(`${baseUrl}/memberMessage/list`, {
                    params: { pageNo: val },
                    //请求头配置  
                    headers: { 'authorization': token }
                }).then(res => {
                    // console.log(res.data.success == true)
                    if (res.data.success == true) {
                        this.memberMessageList = res.data.result.records;
                    }
                })
            }




        }
    }
}
//
</script>

<style lang="scss" scoped>
::v-deep .tableTextColor_1 {
    color: red;
}

::v-deep .tableTextColor_2 {

    color: #1890FF;
}

.weidu {
    font-size: 14px;
    font-weight: bold;
    color: #D4252C;
}

.yidu {
    font-size: 14px;
    font-weight: bold;
    color: #1890FF;
}

.mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;

    .isreadBox {
        position: absolute;
        width: 430px;
        min-height: 300px;
        padding-bottom: 20px;
        padding-top: 20px;
        left: 50%;
        top: 100px;
        transform: translateX(-50%);
        width: 430px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .datas {
            text-align: center;

            span {
                font-size: 30px;
                font-weight: bold;
                color: #333333;
                text-align: center;
                margin-top: 30px
            }
        }

        .contentRead {
            width: 370px;
            padding: 6px 16px;
            border: 1px solid #DCDFE6;
            margin: 10px auto;
            border-radius: 4px;
            height: 150px;
            margin-top: 40px;
        }

        .readY {
            text-align: center;
            width: 370px;
            height: 40px;
            background: #1890FF;
            box-shadow: 0px 3px 6px 1px rgb(24 144 255 / 30%);
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            margin: 16px auto 0;
            cursor: pointer;
        }
    }
}

.messages {
    margin: 0 auto;
    // max-width: 1526px;
    // min-height: 690px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    padding-top: 24px;
    padding-left: 32px;

    .message_Title {
        display: flex;
        align-items: center;

        .el-button {
            width: 120px;
            height: 40px;
            background: #FFC13B;
            box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
            border-radius: 4px 4px 4px 4px;
            border-color: #FFC13B;
            font-size: 16px;
            font-weight: 400;
            color: #2B2B2B;
            // line-height: 40px;
        }

        .messBth {
            display: flex;
            align-items: center;
            margin-left: 24px;
            font-size: 16px;
            font-weight: 400;
            color: #2B2B2B;
            cursor: pointer;

            input {
                width: 20px;
                height: 20px;
                border-radius: 4px 4px 4px 4px;
                cursor: pointer;
            }

            span {
                line-height: 40px;
                margin-left: 8px;
            }
        }
    }

    .tabs {
        margin-top: 38px;
        padding-right: 24px;
        padding-bottom: 32px;

        .tabsContent {
            width: 700px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .paging {
        display: flex;
        justify-content: flex-end;
        position: relative;
        // float: right;
        margin-top: 32px;
        // background-color: #FFFFFF;
    }
}
</style>