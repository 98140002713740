<template>
    <div>
        <!-- 消息管理 MessageManagement -->
        <MessageManagement_Component></MessageManagement_Component>
    </div>
</template>

<script>
import MessageManagement_Component from '../../components/PersonalCenter/MessageManagement_Component.vue'
export default {
    name: "MessageManagement",
    components: { MessageManagement_Component }
}
</script>

<style>

</style>